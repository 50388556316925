import React from 'react';

import { ESocialPlatformType } from './../ShareLink/ShareLink.constants';
import { ReactComponent as IconLink } from '../../../../public/icons/components/share/Icon-Link.svg';
import { ShareLink } from '../ShareLink';

export type TShareLinksListProps = {
  title?: string;
  finalUrl: string;
  metaText?: string;
};

async function handleCopyUrlToClipboard(url: string): Promise<void> {
  await navigator.clipboard.writeText(url);
}

const ShareLinksList: React.FC<TShareLinksListProps> = props => {
  const { finalUrl, metaText } = props;

  return (
    <>
      <ShareLink
        currentUrl={finalUrl}
        socialPlatform={ESocialPlatformType.Facebook}
      />
      <ShareLink
        title={metaText}
        currentUrl={finalUrl}
        socialPlatform={ESocialPlatformType.Linkedin}
      />
      <ShareLink
        title={metaText}
        currentUrl={finalUrl}
        socialPlatform={ESocialPlatformType.Twitter}
      />
      <ShareLink
        title={metaText}
        currentUrl={finalUrl}
        socialPlatform={ESocialPlatformType.Telegram}
      />
      <div
        className='group cursor-pointer'
        onClick={() => handleCopyUrlToClipboard(finalUrl)}
      >
        <IconLink className='aspect-square h-4 w-4 fill-control-600 transition group-hover:fill-control-650' />
      </div>
    </>
  );
};

export default ShareLinksList;
