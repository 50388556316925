export enum ESocialPlatformType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Telegram = 'telegram',
}

export interface ISocialPlatformLink {
  link: (url: string, title?: string) => string;
}

export interface ISocialPlatformLinkMaking {
  [ESocialPlatformType.Facebook]: ISocialPlatformLink;
  [ESocialPlatformType.Twitter]: ISocialPlatformLink;
  [ESocialPlatformType.Linkedin]: ISocialPlatformLink;
  [ESocialPlatformType.Telegram]: ISocialPlatformLink;
}

export const socialPlatformLinkMaking: ISocialPlatformLinkMaking = {
  [ESocialPlatformType.Facebook]: {
    link: url =>
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
  },
  [ESocialPlatformType.Twitter]: {
    link: (url, title) =>
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url,
      )}&text=${title}`,
  },
  [ESocialPlatformType.Linkedin]: {
    link: (url, title) =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        url,
      )}&summary=${title}`,
  },
  [ESocialPlatformType.Telegram]: {
    link: (url, title) =>
      `https://telegram.me/share/url?url=${encodeURIComponent(
        url,
      )}&text=${title}`,
  },
};
