import { useRouter } from 'next/router';
import React from 'react';

import { ReactComponent as IconShare } from '../../../../public/icons/components/share/Icon-Share.svg';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { ShareLinksList } from '../ShareLinksList';

export interface IShareButtonProps {
  /*
   * Pass url that should be shared on social network
   * Default will be used if not passed
   */
  url?: string;

  /*
   * Text displayed on button
   */
  shareText?: string;

  /*
   * Some sharing services require text provided in addition to url
   * Expected text is a Title of the shared url
   */
  metaText?: string;

  /*
   *Opens by clicking on the arrow
   */
  isOpenByClick?: boolean;
}

const ShareButton: React.FC<IShareButtonProps> = props => {
  const { url, shareText, metaText, isOpenByClick = true } = props;

  const router = useRouter();

  const HOST =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : process.env.NEXT_PUBLIC_APP_HOST;

  const defaultCurrentUrl = `${HOST}${router.asPath}`;

  const finalUrl = url || defaultCurrentUrl;

  return (
    <div className='flex items-stretch'>
      <div className='flex flex-1 items-start'>
        {isOpenByClick ? (
          <Popover placement='bottom-start'>
            <PopoverTrigger className='group flex items-center gap-2 text-sm font-medium text-control-600 transition hover:text-control-650'>
              {shareText}
              <IconShare className='aspect-square h-4 w-4 fill-control-600 transition group-hover:fill-control-650' />
            </PopoverTrigger>
            <PopoverContent className='flex max-w-[12rem] gap-4 rounded-xl bg-surface-50-tooltip/30 p-4 text-xs leading-loose text-interface-1000 shadow-md backdrop-blur'>
              <ShareLinksList metaText={metaText} finalUrl={finalUrl} />
            </PopoverContent>
          </Popover>
        ) : (
          <div className='flex gap-4  '>
            <ShareLinksList metaText={metaText} finalUrl={finalUrl} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareButton;
