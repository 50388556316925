import React from 'react';

import {
  ESocialPlatformType,
  socialPlatformLinkMaking,
} from './ShareLink.constants';
import { ReactComponent as IconFacebook } from '../../../../public/icons/components/share/Icon-Facebook.svg';
import { ReactComponent as IconLinkedin } from '../../../../public/icons/components/share/Icon-LinkedIn.svg';
import { ReactComponent as IconTelegram } from '../../../../public/icons/components/share/Icon-Telegram.svg';
import { ReactComponent as IconTwitter } from '../../../../public/icons/components/share/Icon-Twitter.svg';

export interface IShareLinkProps {
  socialPlatform: ESocialPlatformType;
  title?: string;
  currentUrl: string;
}

const ShareLink: React.FC<IShareLinkProps> = props => {
  const { socialPlatform, title, currentUrl } = props;

  const openLinkInNewTab = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    window.open(e.currentTarget.href, '_blank');
  };

  const shareIconClasses =
    'aspect-square text-control-600 hover:text-control-650 h-4 w-4 fill-control-600 transition group-hover:fill-control-650';

  let icon: React.JSX.Element | null;

  let url = '';

  switch (socialPlatform) {
    case ESocialPlatformType.Facebook:
      icon = <IconFacebook className={shareIconClasses} />;
      url =
        socialPlatformLinkMaking[ESocialPlatformType.Facebook].link(currentUrl);
      break;
    case ESocialPlatformType.Linkedin:
      icon = <IconLinkedin className={shareIconClasses} />;
      url = socialPlatformLinkMaking[ESocialPlatformType.Linkedin].link(
        currentUrl,
        title,
      );
      break;
    case ESocialPlatformType.Twitter:
      icon = <IconTwitter className={shareIconClasses} />;
      url = socialPlatformLinkMaking[ESocialPlatformType.Twitter].link(
        currentUrl,
        title,
      );
      break;
    case ESocialPlatformType.Telegram:
      icon = <IconTelegram className={shareIconClasses} />;
      url = socialPlatformLinkMaking[ESocialPlatformType.Telegram].link(
        currentUrl,
        title,
      );
      break;
    default:
      icon = null;
      break;
  }

  return (
    <a className='group' onClick={openLinkInNewTab} href={url}>
      {icon}
    </a>
  );
};

export default ShareLink;
