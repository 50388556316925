import clsx from 'clsx';
import React from 'react';

import { ERatingBarSize } from './RatingBar.constants';
import { ReactComponent as IconStar } from '../../../../public/icons/icon-star.svg';

export interface IRatingStarLayerProps {
  isHalfStarLayer?: boolean;
  ratingBarSize: ERatingBarSize;
  hasVotedBefore?: boolean;
  shouldHighlight?: boolean;
  onClick: () => void;
}

const StarLayer: React.FC<IRatingStarLayerProps> = props => {
  const {
    isHalfStarLayer = false,
    ratingBarSize,
    hasVotedBefore,
    shouldHighlight = false,
    onClick,
  } = props;

  const starSize = ratingBarSize === ERatingBarSize.Large ? '5' : '4';

  return (
    <div
      className={clsx(
        ratingBarSize === ERatingBarSize.Small && 'px-1',
        ratingBarSize === ERatingBarSize.Large && 'px-1.5',
        isHalfStarLayer && 'absolute left-0 top-0 w-6/12 overflow-hidden pr-0',
        !hasVotedBefore && 'cursor-pointer hover:text-brand-500',
        shouldHighlight
          ? 'text-brand-500'
          : isHalfStarLayer
            ? 'text-brand-500/0'
            : 'text-brand-500/40',
      )}
      onClick={onClick}
    >
      <IconStar
        className={`w-${starSize} h-${starSize} fill-current transition`}
      />
    </div>
  );
};

export default StarLayer;
